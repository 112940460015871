import { useState, useEffect, useContext } from 'react'
import BookingForm from './components/bookingform/bookingform'
import Lightbox from './components/lightbox/lightbox';
import Loader from './components/loader/loader';
import baseurl from '../src/utilities/api'
import CalendarHeader from './components/calendar/header';
import Calendar from './components/calendar/calendar'
import { ClassContext } from './contexts/class';
import './App.css';

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [classes, setClasses] = useState([]);
  const [coords, setCoords] = useState(null)
  const [date, setDate] = useState(new Date())
  const [show, setShow] = useState(false)
  const { classtype, setTribaClass } = useContext(ClassContext)
  const hostB64 = btoa(window.location.host); 

  useEffect(() => {

    fetch(`${baseurl}/api/calendar`, {
      headers: {
        Authorization: `Bearer ${hostB64}`
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          // TODO: This sorting should not be neccesary on the front end, and concord should take care of it
          result.classes.sort(function (a, b) {
            return new Date('1970/01/01 ' + a.start_time) - new Date('1970/01/01 ' + b.start_time);
          });
          // Sort all the classes into 7 arrays, 0-6 for days in a week.
          const classes = [[], [], [], [], [], [], []]
          for (const element of result.classes) {
            classes[element.day_of_week].push(element)
          }
          setClasses(classes);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function changeDate(direction) {
    if (direction === 'up') {
      setDate(date.addDays(7));
      return
    }

    setDate(date.addDays(-7));
  }

  async function setSelectClass(e, tribaClass) {
    if(tribaClass.disabled === 'disabled') {
      return false
    }
    const coords = e.currentTarget.getBoundingClientRect()
    tribaClass.selectedDate = e.currentTarget.dataset.date
    tribaClass.classtype = classtype
    setCoords(coords);
    setTribaClass(tribaClass)
    setShow(true)
  }

  if (error) {
    return <p>{error}</p>
  }

  if (isLoaded) {
    return (
      <div className={classtype + " triba-calendar"}>
        <CalendarHeader 
          changeDate={changeDate} 
          date={date} 
          setDate={setDate} 
        />
        <div id='triba-calendar-wrapper'>
        <Lightbox show={show} setShow={setShow} coords={coords} >
          <BookingForm setShow={setShow} />
        </Lightbox>
        <Calendar 
          classes={classes} 
          date={date} 
          setSelectClass={setSelectClass} 
        />
        </div>
      </div >
    );
  } else {
    return (
      <Loader />
    )
  }
}

export default App;
