import { useContext } from "react";
import { ClassContext } from "../../contexts/class";
import * as amplitude from '@amplitude/analytics-browser';
import { CustomizeContext } from "../../contexts/customize";

function Calendar(props) {
    const { classes, date, setSelectClass } = props
    const dateOptions = { weekday: 'short', month: 'short', day: 'numeric' };
    const { classtype } = useContext(ClassContext)
    const { fontColor } = useContext(CustomizeContext)

    const complement = `${fontColor}15`;
    
    const customStyle = {
        backgroundColor: `#${complement}`,
        color: `#${fontColor}`
    }
    return (
        <ul>
            {Array.apply(null, { length: 7 }).map((n, i) => {
                const calendarDate = date.addDays(i)
                return (
                    <li key={calendarDate.getDate()}>
                        <span 
                            className='calendardate'
                            style={customStyle}
                        >
                            {calendarDate.toLocaleDateString("en-GB", dateOptions)}
                        </span>
                        {classes[calendarDate.getDay()].map((tribaClass) => {
                            if (classtype === 'studio' && tribaClass.studio_price_state === 0) {
                                return null
                            }

                            if (classtype === 'stream' && tribaClass.stream_price_state === 0) {
                                return null
                            }

                            if (new Date(tribaClass.start_date.replace(/\s/, 'T')).getTime() < calendarDate.setHours(23, 59, 59, 999) && new Date(tribaClass.end_date.replace(/\s/, 'T')).getTime() > calendarDate.setHours(0, 0, 0, 0)) {
                                const time = tribaClass.start_time.split(':')
                                const classDate = calendarDate.setHours(time[0], time[1])
                                const classdate = new Date(classDate)
                                const year = classdate.getFullYear();
                                let month = classdate.getMonth()+1;
                                let dt = classdate.getDate();
                                let hour = classdate.getHours()
                                let minute = classdate.getMinutes()

                                if (dt < 10) {
                                    dt = '0' + dt;
                                }

                                if (month < 10) {
                                    month = '0' + month;
                                }

                                if (hour < 10) {
                                    hour = '0' + hour;
                                }

                                if (minute < 10) {
                                    minute = '0' + minute;
                                }
                                
                                const datestring = `${year}-${month}-${dt} ${hour}:${minute}`;
                                const disabled = classdate < new Date() ? 'disabled' : ''
                                tribaClass.disabled = disabled

                                return (
                                    <div 
                                        key={`${tribaClass.classid}${tribaClass.tid}`} 
                                        className={`calendarClass ${tribaClass.category} ${classtype} ${disabled}`} 
                                        data-date={datestring} 
                                        disabled={disabled} 
                                        onClick={(e) => { 
                                            amplitude.track('Calendar item selected');
                                            setSelectClass(e, tribaClass) 
                                        }}
                                        style={customStyle}
                                    >
                                        <span>{tribaClass.name}</span>
                                        <span className='calendarClass--time'>{tribaClass.start_time} - {tribaClass.end_time}</span>
                                        <span>{tribaClass.firstname} {tribaClass.lastname}</span>
                                        <img alt={tribaClass.name} src={tribaClass.image_url} />
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </li>
                )
            })}
        </ul>
    )
}

export default Calendar;