import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import baseurl from '../../utilities/api'

export const CustomizeContext = createContext()
export const CustomizeConsumer = CustomizeContext.Consumer

const CustomizeContextProvider = ({ children }) => {
    const [fontColor, setFontColor] = useState(null)
    useEffect(() => {
       const hostB64 = btoa(window.location.host); 
       fetch(`${baseurl}/api/customize`, {
           headers: {
               Authorization: `Bearer ${hostB64}`
           }
        })
        .then(response => response.json())
        .then(data => setFontColor(data.customize.fontColor))
        .catch(error => console.error(error));
    })

    return (
        <CustomizeContext.Provider 
            value={{
                fontColor,
            }}
        >
            {children}
        </CustomizeContext.Provider>
    );
}

CustomizeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default CustomizeContextProvider